import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import './styles.scss'
import Layout from '../../components/Layout'
import Content, {HTMLContent} from '../../components/Content'
import SharedJumbotron from '../../components/SharedJumbotron'

type TermsPageTemplate = {
  content: Node.isRequired,
  contentComponent: any,
  downloadNow: {
    mainText: string,
    subText: string,
    image: any,
  },
  headerImage: any,
}

export const TermsPageTemplateExport = (props: TermsPageTemplate) => {
  const PostContent = props.contentComponent || Content
  const {content, headerImage, downloadNow} = props

  return (
    <div className="terms-page">
      <SharedJumbotron headerImage={headerImage} />

      <section className="blog-post-container" style={{paddingBottom: '120px'}}>
        <div className="container content">
          <div className="row justify-content-center">
            <div className="col col-10 agreement-container">
              <h2>SNOWBALL X WEBSITE TERMS OF USE</h2>
              <h3>Agreements</h3>
              <div className="item-container">
                {props.agreements.map((agreement, ndx) => (
                  <Yo className="pt-1" key={`agreement-${ndx}`}>
                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                      <p className="mb-0" style={{fontWeight: '500'}}>
                        {agreement.title}{' '}
                      </p>
                      <img className='pdf' alt='' src={require('../../img/icon_pdf.svg')}/>
                    </span>
                    <a
                      className="yo-2 mb-0"
                      style={{fontWeight: '500'}}
                      download
                      href={agreement.file.publicURL}
                    >
                      VIEW
                    </a>
                  </Yo>
                ))}
              </div>
              
            </div>

            <div className="col col-10 declaration-container">
              <h3>Declarations</h3>
              <div className="item-container">
                {props.declarations.map((declaration, ndx) => (
                  <Yo className="pt-1" key={`declaration-${ndx}`}>
                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                      <p className="mb-0" style={{fontWeight: '500'}}>
                        {declaration.title}{' '}
                      </p>
                      <img className='pdf' alt='' src={require('../../img/icon_pdf.svg')}/>
                    </span>
                    <a
                      className="yo-2 mb-0"
                      style={{fontWeight: '500'}}
                      download
                      href={declaration.file.publicURL}
                    >
                      VIEW
                    </a>
                  </Yo>
                ))}
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const TermsPage = ({data}) => {
  const {markdownRemark: post} = data

  return (
    <Layout footerLinks={post.frontmatter.footerLinks}>
      <TermsPageTemplateExport
        content={post.html}
        contentComponent={HTMLContent}
        downloadNow={post.frontmatter.downloadNow}
        headerImage={post.frontmatter.headerImage}
        agreements={post.frontmatter.agreements}
        declarations={post.frontmatter.declarations}
      />
    </Layout>
  )
}

TermsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

const Yo = styled.div`
  min-height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EEEEEE;
  padding: 0px 20px;

  .yo-2 {
    color: #006fbb;
  }

  &:nth-child(2) {
    border-top: 1px solid #EEEEEE;
  }
`

export default TermsPage

export const pageQuery = graphql`
  query TermsAndConditions($id: String!) {
    markdownRemark(id: {eq: $id}) {
      id
      html
      frontmatter {
        headerImage {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        agreements {
          title
          file {
            publicURL
          }
        }
        declarations {
          title
          file {
            publicURL
          }
        }
        downloadNow {
          mainText
          subText
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        footerLinks {
          firstRow {
            name
            href
          }
          secondRow {
            name
            href
          }
        }
      }
    }
  }
`
